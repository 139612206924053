import React from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import { slidingAnimation } from 'styles/utilities';

import { AnimatedOverlay } from '../components/animated-overlay';
import { AnimationWrapper } from '../components/animation-wrapper';

import { useTechnologiesData } from './hooks';

const STechnologiesAnimation = styled(AnimationWrapper)`
    position: relative;

    #message {
        ${slidingAnimation(0, 10)};
        top: 0;
        left: 65%;
        width: 10%;
    }
    #arrow {
        ${slidingAnimation(10, 0)};
        top: 37%;
        left: 0;
        width: 18%;
    }
    #triangle-1 {
        ${slidingAnimation(10, -10)};
        top: 65%;
        left: 0;
        width: 16%;
    }
    #triangle-2 {
        ${slidingAnimation(-10, -10)};
        top: 61%;
        left: 23%;
        width: 3.5%;
    }
    #triangle-3 {
        ${slidingAnimation(10, -10)};
        top: 50%;
        left: 85%;
        width: 3.5%;
    }
    #triangle-4 {
        ${slidingAnimation(-10, -10)};
        top: 84%;
        left: 87%;
        width: 4.5%;
    }
    #shape-1 {
        ${slidingAnimation(-10, 10)};
        top: 20%;
        left: 84%;
        width: 10%;
    }
`;

export const AnimatedTechnologies = ({ isHero }) => {
    const {
        technologies,
        message,
        arrow,
        triangle1,
        triangle2,
        triangle3,
        triangle4,
        shape1,
    } = useTechnologiesData();

    return (
        <STechnologiesAnimation image={technologies} isHero={isHero}>
            <AnimatedOverlay id="message" image={message} />
            <AnimatedOverlay id="arrow" image={arrow} />
            <AnimatedOverlay id="triangle-1" image={triangle1} />
            <AnimatedOverlay id="triangle-2" image={triangle2} />
            <AnimatedOverlay id="triangle-3" image={triangle3} />
            <AnimatedOverlay id="triangle-4" image={triangle4} />
            <AnimatedOverlay id="shape-1" image={shape1} />
        </STechnologiesAnimation>
    );
};

AnimatedTechnologies.propTypes = {
    isHero: bool,
};
