import { graphql, useStaticQuery } from 'gatsby';

export const useDevelopersData = () => {
    return useStaticQuery(graphql`
        {
            developers: file(
                relativePath: {
                    eq: "animated-images/developers/developers.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            speechBubble: file(
                relativePath: {
                    eq: "animated-images/developers/speech-bubble.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            message: file(
                relativePath: { eq: "animated-images/developers/message.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            dot: file(
                relativePath: { eq: "animated-images/developers/dot.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle1: file(
                relativePath: {
                    eq: "animated-images/developers/triangle-1.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle2: file(
                relativePath: {
                    eq: "animated-images/developers/triangle-2.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle3: file(
                relativePath: {
                    eq: "animated-images/developers/triangle-3.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    `);
};
