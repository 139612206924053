import React from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import { slidingAnimation } from 'styles/utilities/index';

import { AnimatedOverlay } from '../components/animated-overlay';
import { AnimationWrapper } from '../components/animation-wrapper';

import { useDevelopersData } from './hooks';

const SDevelopersAnimation = styled(AnimationWrapper)`
    position: relative;

    #speech-bubble {
        ${slidingAnimation(0, 10)};
        width: 13%;
        top: 0;
        left: 54%;
    }
    #triangle-1 {
        ${slidingAnimation(-10, 10)};
        width: 8%;
        top: 59%;
        left: 40%;
    }
    #triangle-2 {
        ${slidingAnimation(10, 10)};
        width: 1.7%;
        top: 75%;
        left: 48%;
    }
    #triangle-3 {
        ${slidingAnimation(10, 10)};
        width: 6.5%;
        top: 37.5%;
        left: 79%;
    }
    #dot {
        ${slidingAnimation(10, -10)};
        width: 1%;
        top: 32%;
        left: 77%;
    }
    #message {
        ${slidingAnimation(0, -10)};
        width: 21%;
        top: 73%;
        left: 55%;
    }
`;

export const AnimatedDevelopers = ({ isHero }) => {
    const {
        developers,
        speechBubble,
        message,
        dot,
        triangle1,
        triangle2,
        triangle3,
    } = useDevelopersData();

    return (
        <SDevelopersAnimation image={developers} isHero={isHero}>
            <AnimatedOverlay id="speech-bubble" image={speechBubble} />
            <AnimatedOverlay id="message" image={message} />
            <AnimatedOverlay id="dot" image={dot} />
            <AnimatedOverlay id="triangle-1" image={triangle1} />
            <AnimatedOverlay id="triangle-2" image={triangle2} />
            <AnimatedOverlay id="triangle-3" image={triangle3} />
        </SDevelopersAnimation>
    );
};

AnimatedDevelopers.propTypes = {
    isHero: bool,
};
