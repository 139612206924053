import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { AnimatedTechnologies } from 'modules/animated-images/technologies';
import { string } from 'prop-types';

const SContainer = styled(Container)`
    display: flex;
    align-items: center;
    gap: 3.625rem;
    content-visibility: auto;
    contain-intrinsic-size: 500px;
    ${CONSTANTS.MEDIA.max1024`
        gap: 2rem;
        flex-direction: column;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 1.4rem;
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 3rem;
`;

const SWrapper = styled.div`
    flex: 0.5;
`;

const SSWrapper = styled.div`
    flex: 0.5;
    max-width: 33.938rem;
    width: 100%;
`;

export const TechnologiesSection = ({ svgTitle }) => {
    const title = 'main-page.technologies-section.technologiesWeUse';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id="technologies">
            <SSWrapper>
                <AnimatedTechnologies title={svgTitle} />
            </SSWrapper>
            <SWrapper>
                <SHeaderSecond
                    topParagraph="main-page.technologies-section.technologies"
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <SParagraph>
                    <FormattedMessage id="main-page.technologies-section.inMobileReality" />
                </SParagraph>
                <Button
                    to={PATHS.TECHNOLOGIES}
                    variant="dark"
                    component="gatsby-link"
                >
                    <FormattedMessage id="general.learnMore" />
                </Button>
            </SWrapper>
        </SContainer>
    );
};

TechnologiesSection.prototype = {
    svgTitle: string,
};
