import { graphql, useStaticQuery } from 'gatsby';

export const useTechnologiesData = () => {
    return useStaticQuery(graphql`
        {
            technologies: file(
                relativePath: {
                    eq: "animated-images/technologies/technologies.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            arrow: file(
                relativePath: { eq: "animated-images/technologies/arrow.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            message: file(
                relativePath: { eq: "animated-images/technologies/message.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            shape1: file(
                relativePath: { eq: "animated-images/technologies/shape-1.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle1: file(
                relativePath: {
                    eq: "animated-images/technologies/triangle-1.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle2: file(
                relativePath: {
                    eq: "animated-images/technologies/triangle-2.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle3: file(
                relativePath: {
                    eq: "animated-images/technologies/triangle-3.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
            triangle4: file(
                relativePath: {
                    eq: "animated-images/technologies/triangle-4.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    `);
};
