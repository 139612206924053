import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

export const useProjectsData = () => {
    const projectsData = useStaticQuery(graphql`
        {
            awsCostOptimization: file(
                relativePath: { eq: "case-studies/aws-cost-optimization.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                        formats: [WEBP]
                    )
                }
            }
            moneyTransferApplication: file(
                relativePath: {
                    eq: "case-studies/money-transfer-application-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    const path = 'main-page.our-latest-projects-section';
    return [
        {
            id: 'awsCostOptimization',
            title: `${path}.aws-cost-optimization.title`,
            desc: `${path}.aws-cost-optimization.description`,
            alt: `${path}.aws-cost-optimization.alt`,
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            img: projectsData.awsCostOptimization.childImageSharp,
            link: `${PATHS.CASE_STUDIES}/${PATHS.AWS_COST_OPTIMIZATION}`,
        },
        {
            id: 'moneyTransferApplication',
            title: `${path}.money-transfer-application.title`,
            desc: `${path}.money-transfer-application.description`,
            alt: `${path}.money-transfer-application.alt`,
            tags: [
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.REACT_NATIVE_DEVELOPMENT,
            ],
            link: `${PATHS.CASE_STUDIES}/${PATHS.MONEY_TRANSFER_APPLICATION}`,
            img: projectsData.moneyTransferApplication.childImageSharp,
        },
    ];
};
