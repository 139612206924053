import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bool, node, object, string } from 'prop-types';

const SWrapper = styled.div`
    position: relative;
`;

const SGatsbyImage = styled(GatsbyImage)`
    user-select: none;
`;

export const AnimationWrapper = ({ children, image, isHero, className }) => {
    return (
        <SWrapper className={className}>
            <SGatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                placeholder="none"
                draggable={false}
                alt=""
                imgStyle={{ transition: 'none' }}
                {...(isHero && { loading: 'eager', fetchpriority: 'high' })}
            />
            {children}
        </SWrapper>
    );
};

AnimationWrapper.propTypes = {
    children: node,
    className: string,
    image: object.isRequired,
    isHero: bool,
};

AnimationWrapper.defaultProps = {
    isHero: false,
};
