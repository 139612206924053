import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const SAnimatedOverlay = styled.div`
    position: absolute;
    user-select: none;
`;

export const AnimatedOverlay = ({ image, id }) => {
    return (
        <SAnimatedOverlay id={id}>
            <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                draggable={false}
                alt=""
            />
        </SAnimatedOverlay>
    );
};

AnimatedOverlay.propTypes = {
    image: object,
    id: string,
};
