import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CaseStudiesSection } from 'views/index/case-studies-section';
import { ClutchSection } from 'views/index/clutch-section';
import { ExpertisesSection } from 'views/index/expertises-section';
import { HomeMainSection } from 'views/index/home-main-section';
import { OpenSourceSection } from 'views/index/open-source-section';
import { OurLatestProjectsSection } from 'views/index/our-latest-projects';
import { ServicesSection } from 'views/index/services-section';
import { TechnologiesSection } from 'views/index/technologies-section';
import { SectionWrapper } from 'components/section-wrapper';
import { CareersSection } from 'modules/careers-section';
import { FAQSection } from 'modules/faq-section';
import { LatestArticles } from 'modules/latest-articles';
import { RequestSection } from 'modules/request-section';
import { TrustedSection } from 'modules/trusted-section';
import { array } from 'prop-types';

import { NFTRealitySection } from './nft-reality-section';

const SCareersSectionWrapper = styled.div`
    .careersContainer {
        background-color: hsla(223, 78%, 8%, 1);
        background-image: radial-gradient(
                at 10% 20%,
                hsla(187, 57%, 53%, 0.25) 0,
                transparent 50%
            ),
            radial-gradient(
                at 85% 60%,
                hsla(302, 64%, 36%, 0.25) 0,
                transparent 50%
            );
    }
`;

const Props = {
    blogPosts: array.isRequired,
    faqData: array.isRequired,
};

export const Index = ({ blogPosts, faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <HomeMainSection />
            <OurLatestProjectsSection />
            <TrustedSection />
            <ServicesSection />
            <CaseStudiesSection />
            <ExpertisesSection />
            <ClutchSection />
            <SectionWrapper>
                <TechnologiesSection
                    svgTitle={formatMessage({
                        id: 'main-page.technologies-section.svgTitle',
                    })}
                />
                <RequestSection
                    alt={formatMessage({ id: 'main-page.request-section.alt' })}
                />
                <OpenSourceSection />
            </SectionWrapper>
            <LatestArticles latestPostsList={blogPosts} fillBackground />
            <NFTRealitySection />
            <FAQSection
                title={faqData.title}
                data={faqData.data}
                withTranslations={false}
                parseRawAnswer
                rawTitle
            />
            <SCareersSectionWrapper>
                <CareersSection
                    containerClassName="careersContainer"
                    imageAltsIds={{
                        teamMemberRed: 'main-page.careers.teamMemberRedAlt',
                        strongman: 'main-page.careers.strongmanAlt',
                        bookLover: 'main-page.careers.bookLoverAlt',
                        teamMemberBlack: 'main-page.careers.teamMemberBlackAlt',
                    }}
                />
            </SCareersSectionWrapper>
        </>
    );
};

Index.protoTypes = Props;
